import cn from 'classnames';
import { useRouter } from 'next/router';
import {
  ButtonHTMLAttributes,
  forwardRef,
  JSXElementConstructor,
  LegacyRef,
  MouseEvent,
  MutableRefObject,
  Ref,
  useMemo,
  useRef,
} from 'react';
import mergeRefs from 'react-merge-refs';

import { Link, LoadingDots } from '@components/ui';
import { isExternalLink } from '@lib/check-external-link';
import { GTagEvent, trackEvent } from '@lib/gtag';
import { cleanQueryParams, urlEncode } from '@lib/http';
import { getQueryStringFromUrl } from '@lib/query-string';
import { Maybe } from '@lib/utility-types';

import s from './Button.module.scss';

export type ButtonVariant = 'regular' | 'slim' | 'cta' | 'link';

export interface ButtonProps<T extends Maybe<string> = undefined>
  extends ButtonHTMLAttributes<T extends string ? HTMLAnchorElement : HTMLButtonElement> {
  href?: T;
  target?: string;
  className?: string;
  variant?: ButtonVariant;
  active?: boolean;
  type?: 'submit' | 'reset' | 'button';
  Component?: string | JSXElementConstructor<any>;
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
  ref?: Ref<any>;
  gtm?: GTagEvent;
  size?: 'sm' | 'md' | 'lg';
  clearQuery?: boolean;
  prefetch?: boolean;
}

const Button = forwardRef(
  <T extends Maybe<string>>(props: ButtonProps<T>, buttonRef: MutableRefObject<any> | LegacyRef<any>) => {
    const {
      href,
      target,
      className,
      variant = 'regular',
      children,
      active,
      width,
      loading = false,
      disabled = false,
      style = {},
      size,
      Component,
      onClick,
      gtm,
      clearQuery = false,
      ...rest
    } = props;

    const Cta: string | JSXElementConstructor<any> = Component ?? (href ? Link : 'button');
    const ref = useRef<typeof Component>(null);
    const router = useRouter();
    const isDisabled = disabled || loading;
    const anchorProperties: Partial<HTMLAnchorElement> = useMemo(() => {
      if (!href) {
        return {};
      }

      if (isExternalLink(href)) {
        return { href, target: target ?? '_blank' };
      }

      // router.query will include NextJS dynamic params
      const params = getQueryStringFromUrl(router?.asPath ?? '');
      const updatedQuery = clearQuery ? cleanQueryParams(params) : params;

      const url = new URL(href, 'https://ignored-url');
      const query = urlEncode(
        {
          ...Object.fromEntries(url.searchParams.entries()),
          ...updatedQuery,
        },
        true
      );

      return {
        href: `${url.pathname}${query && '?'}${query}`,
        target: target ?? '_self',
      };
    }, [href, router?.asPath, target, clearQuery]);

    const onClickWithGTM = (e: MouseEvent<T extends string ? HTMLAnchorElement : HTMLButtonElement>) => {
      // This event always executes in an `a` tag even in a disabled state
      // because of that we disable the execution manually
      if (isDisabled) {
        e.preventDefault();
        return;
      }

      if (gtm) {
        trackEvent(gtm);
      }
      onClick?.(e);
    };

    const rootClassName = cn(
      {
        [s.root]: variant !== 'link',
        [s.slim]: variant === 'slim',
        [s.cta]: variant === 'cta',
        [s.disabled]: isDisabled,
        [s[size ?? '']]: size !== undefined && !['link', 'slim'].includes(variant),
      },
      className
    );

    return (
      <Cta
        aria-pressed={active}
        data-variant={variant}
        ref={mergeRefs([ref, buttonRef])}
        className={rootClassName}
        disabled={isDisabled}
        onClick={onClickWithGTM}
        style={{ width, ...style }}
        {...rest}
        {...anchorProperties}
      >
        {loading ? (
          <i className="pl-2 m-0 flex">
            <LoadingDots />
          </i>
        ) : (
          <>{children}</>
        )}
      </Cta>
    );
  }
);

Button.displayName = 'Tile Button';

export default Button;
