import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

export function getQueryString(router: NextRouter, additional?: Record<string, string | undefined>): ParsedUrlQuery {
  const { query } = router;
  return { ...query, ...additional };
}

export function deleteQueryString(router: NextRouter, toDelete: string[]): ParsedUrlQuery {
  const updatedQuery: ParsedUrlQuery = { ...router.query };
  toDelete.forEach((key) => {
    delete updatedQuery[key];
  });
  return updatedQuery;
}

export function parseQueryString(qs: string): Record<string, string> {
  return qs
    .split('&')
    .filter((query) => !!query)
    .reduce((qObj, query) => {
      const [key, value] = query.split('=');
      return { ...qObj, [key]: decodeURIComponent(value) };
    }, {});
}

export function getQueryStringFromUrl(url: string): Record<string, string> {
  const qs = url.split('?')[1];
  if (!qs) {
    return {};
  }

  return parseQueryString(qs);
}

export function updateQueryParam(router: NextRouter, type: string, value?: string | null) {
  const { query, asPath } = router;
  const { categorySlug, ...cleanedQuery } = query;
  const pathname = asPath.split('?')[0];
  const updatedQuery = { ...cleanedQuery };
  if (value) {
    updatedQuery[type] = value;
  } else if (type in updatedQuery) {
    delete updatedQuery[type];
  }
  router.replace({ pathname, query: updatedQuery }, undefined, { shallow: true });
}
